import http from "../../services/http";
import endpoints from "../../services/endpoints";
import { EnquiryService } from "../enquiry-report-service";
import { ReportService } from "api/portal";

export default class Diagnostics {
  constructor(inProgress) {
    this.inProgress = inProgress;
  }

  async getStatus(enquiryId) {
    const report = await ReportService.getReports({ reportId: enquiryId });

    return report.state;
  }

  async overview(enquiryId) {
    this.inProgress(true);
    try {
      return await EnquiryService.getV1EnquiryLegacyState({
        enquiryId
      });
    } finally {
      this.inProgress(false);
    }
  }

  content(enquiryId) {
    return new Promise((resolve, reject) => {
      this.inProgress(true);
      http
        .post(`${endpoints.diagnosticsContent}`, { EnquiryId: enquiryId })
        .then(
          s => {
            this.inProgress(false);
            resolve(s);
          },
          () => {
            this.inProgress(false);
            reject();
          }
        );
    });
  }

  graph() {
    return new Promise((resolve, reject) => {
      reject("not implemented");
    });
  }
}
