import { styled } from "@linaria/react";

import { ReactComponent as SanctionsIconSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsIconSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as PepsIconSvg } from "img/icons/state-owned.svg";
import { ReactComponent as FlagSvgIconSvg } from "img/icons/flag-icon.svg";
import { ReactComponent as FinancialCrimesIconSvg } from "img/icons/fin-crime.svg";
import { ReactComponent as ProceedingsIconSvg } from "img/icons/law-icon.svg";

import { fontSize } from "styles/fonts";
import { grey, standardColors, red } from "styles/colors";

const RiskIcons = styled.div`
  display: flex;
  padding: 1rem 0;

  & > div {
    margin: -4px;
  }
`;

const RiskIcon = styled.div<{ isActive: boolean }>`
  svg {
    path {
      fill: ${({ isActive }) =>
        isActive ? red.directRiskFill : grey.disabled};
    }
  }

  &.loading {
    path {
      animation: loadingIcon 1.5s ease-in-out 0.5s infinite;
    }
  }

  @keyframes loadingIcon {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TooltipContent = styled.p`
  margin: 0;
  padding: 0.875rem;
  font-size: ${fontSize.sm};
  max-width: 23.125rem;
`;

const iconStyles = {
  width: "2rem",
  height: "2rem",
  background: standardColors.white,
  "border-radius": "50%"
};

const OtherIcon = styled(FlagSvgIconSvg)`
  ${iconStyles};
`;

const SanctionsIcon = styled(SanctionsIconSvg)`
  ${iconStyles};
`;

const WatchlistsIcon = styled(WatchlistsIconSvg)`
  ${iconStyles};
`;

const PepsIcon = styled(PepsIconSvg)`
  ${iconStyles};
`;

const FinancialCrimesIcon = styled(FinancialCrimesIconSvg)`
  ${iconStyles};
`;

const ProceedingsIcon = styled(ProceedingsIconSvg)`
  ${iconStyles};
`;

const Tooltip = styled.div`
  padding: 16px;
`;

const S = {
  RiskIcons,
  RiskIcon,
  TooltipContent,
  SanctionsIcon,
  WatchlistsIcon,
  PepsIcon,
  ProceedingsIcon,
  FinancialCrimesIcon,
  OtherIcon,
  Tooltip
};

export default S;
