import { ENTITY_TYPE } from "pages/define/utils";

import { blue, green } from "styles/colors";

const theme = {
  primaryColor: green.xapienGreen,
  darkPrimaryColor: green.dark,
  alternativePrimaryColor: blue.xapienLight,
  dropdownColor: "#4f94b4",
  button: {
    primary: {
      color: blue.xapienDark
    },
    tertiary: {
      color: blue.xapienLight
    },
    boxShadowColor: "rgba(31, 204, 192, 0.33)",
    alternativeBoxShadowColor: "rgba(40, 239, 250, 0.33)"
  },
  backgroundColor: blue.xapienDark,
  defaultSubjectType: ENTITY_TYPE.Person,
  slogan: "Xapien is a fully-automated research platform"
};

export default theme;
