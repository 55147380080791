import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {
  getNationalityFromCountryCode,
  summarizeDescription
} from "util/personaUtils";

import { blue } from "styles/colors";
import { TopEntityTag } from "api/reportPersonas/types";
import { RiskSummaryTopic } from "api/insights";
import RiskSummariesContext from "util/hooks/useRiskSummaries/context";
import RiskSummaryInfoGraphic from "components/molecules/RiskSummaryInfoGraphic";
import { RiskSummariesStatus } from "util/hooks/useRiskSummaries";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { usePersonaRiskSummariesContextValue } from "util/createPersonaRiskSummariesContextValue";
import Tag from "components/atoms/Tag";
import S from "./styles";

interface PersonaCardProps {
  name: string;
  imageKey: string;
  xiSummary: string;
  personaId: string;
  dob: string;
  numberOfDres: number;
  countryCodeNationality?: string;
  handleClick?: () => void;
  topEntityTags: TopEntityTag[];
}

function isTruncated(el: HTMLDivElement) {
  return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
}

function calculateSizeString(size: number): string {
  if (size < 5) return "Tiny";
  if (size < 10) return "Small";
  if (size < 50) return "Medium";
  if (size < 200) return "Large";
  return "Very Large";
}

export const PersonaCard = ({
  imageKey,
  xiSummary,
  personaId,
  name,
  dob,
  numberOfDres,
  countryCodeNationality,
  handleClick,
  topEntityTags
}: PersonaCardProps) => {
  const personaRiskSummaries = usePersonaRiskSummariesContextValue(personaId);
  const ref = useRef<HTMLDivElement>(null);
  const [truncateText, setTruncateText] = useState(true);
  const [hasImageErrored, setHasImageErrored] = useState(false);
  const [showExpandCollapse, setShowExpandCollapse] = useState(false);

  useEffect(() => {
    const targetDiv = ref.current;
    if (!targetDiv) return;

    const resizeObserver = new ResizeObserver(() => {
      if (isTruncated(targetDiv)) {
        setShowExpandCollapse(true);
      }
    });

    resizeObserver.observe(targetDiv);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!!ref.current && isTruncated(ref.current)) {
      setShowExpandCollapse(true);
      setTruncateText(true);
    }
  }, [ref]);

  const { status } = personaRiskSummaries;
  let riskSummariesComponent: ReactElement<any, any>;
  switch (status) {
    case RiskSummariesStatus.Loading:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          Loading
        </S.CardTextContainer>
      );
      break;
    case RiskSummariesStatus.Loaded:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          <RiskSummariesContext.Provider value={personaRiskSummaries}>
            <S.PersonaCardRiskIcons>
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.SANCTIONS}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.SANCTIONS)
                    ?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.WATCHLISTS}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.WATCHLISTS)
                    ?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.PEPS}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.PEPS)
                    ?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.FINANCIAL_CRIME}
                summary={
                  personaRiskSummaries.getSummary(
                    RiskSummaryTopic.FINANCIAL_CRIME
                  )?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.ESG}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.ESG)?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.PROCEEDINGS}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.PROCEEDINGS)
                    ?.summary
                }
              />
              <RiskSummaryInfoGraphic
                topic={RiskSummaryTopic.OTHER_FLAGS}
                summary={
                  personaRiskSummaries.getSummary(RiskSummaryTopic.OTHER_FLAGS)
                    ?.summary
                }
              />
            </S.PersonaCardRiskIcons>
          </RiskSummariesContext.Provider>
        </S.CardTextContainer>
      );
      break;
    case RiskSummariesStatus.MutingRiskIcon:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          Muting risk...
        </S.CardTextContainer>
      );
      break;
    case RiskSummariesStatus.Error:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          Not enough content to build a summary
        </S.CardTextContainer>
      ); // TODO - change; not strictly correct
      break;
    case RiskSummariesStatus.NotLoaded:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          Not Loaded
        </S.CardTextContainer>
      );
      break;
    default:
      riskSummariesComponent = (
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          Unknown Status
        </S.CardTextContainer>
      );
      break;
  }

  const { enabled: diagnosticsModeEnabled } = useContext(
    DiagnosticsModeContext
  );

  const sizeString = calculateSizeString(numberOfDres);

  const deduplicatedTags = Array.from(
    new Map(
      topEntityTags.map(tag => [
        `${tag.label}-${tag.relationship?.description || ""}`,
        tag
      ])
    ).values()
  );

  return (
    <S.CardContainer>
      <S.CardInnerContainer>
        <S.CardHeader>
          {!hasImageErrored ? (
            <S.SourceImage
              image={{ imageKey, id: name, thirdPartyContent: null }}
              onError={() => {
                setHasImageErrored(true);
              }}
            />
          ) : (
            <S.PlaceholderIcon />
          )}
          <S.SimpleContainer width="100%" justifyContent="space-between">
            <S.SimpleContainer direction="column">
              <S.Title>{name}</S.Title>
              <S.SimpleContainer>
                {!!countryCodeNationality && (
                  <>
                    <S.CountryFlag
                      size="sm"
                      code={countryCodeNationality}
                      showCodeString={false}
                    />
                    <S.Label>
                      {getNationalityFromCountryCode(countryCodeNationality)}
                    </S.Label>
                    <S.Label>•</S.Label>
                  </>
                )}
                <S.Label>{dob}</S.Label>
              </S.SimpleContainer>
            </S.SimpleContainer>
            <S.SimpleContainer direction="column">
              {!!handleClick && (
                <S.ViewReportButton onClick={handleClick}>
                  <S.EyeIcon />
                  View Report
                </S.ViewReportButton>
              )}
              {diagnosticsModeEnabled && (
                <S.Tooltip
                  tooltip={`Number of DREs: ${numberOfDres}`}
                  maxWidth="300px"
                >
                  <S.Label>{`${sizeString} Report`}</S.Label>
                </S.Tooltip>
              )}
              {!diagnosticsModeEnabled && (
                <S.Label>{`${sizeString} Report`}</S.Label>
              )}
            </S.SimpleContainer>
          </S.SimpleContainer>
        </S.CardHeader>
        {riskSummariesComponent}
        <S.CardTextContainer ref={ref} enableTruncation={truncateText}>
          {xiSummary}
        </S.CardTextContainer>

        {showExpandCollapse && (
          <S.ExpandButton
            onClick={() => setTruncateText(previous => !previous)}
          >
            {truncateText ? (
              <>
                Show more
                <S.ChevronDown />
              </>
            ) : (
              <>
                Show less
                <S.ChevronUp />
              </>
            )}
          </S.ExpandButton>
        )}
      </S.CardInnerContainer>
      <S.TagsContainer>
        {deduplicatedTags.map((tag, index) => {
          const relation = tag.relationship?.description
            ? summarizeDescription(tag.relationship.description)
            : null;

          return (
            <Tag
              key={`${tag.label}-${index}`}
              toolTip={relation ?? undefined}
              color={blue.blueDark}
              label={tag.label}
              isKillable={false}
              isClickable={false}
            />
          );
        })}
      </S.TagsContainer>
    </S.CardContainer>
  );
};
