import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import EntitiesApi from "api/entities";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import EntitySection from "components/organisms/EntitySection";
import RelatedPartiesEntitySectionHelpText from "components/molecules/RelatedPartiesEntitySectionHelpText";
import useEntityList, {
  EntityListContextProvider
} from "util/hooks/useEntityList";

const RelatedPartiesEntitySection = () => {
  const enquiryId = useEnquiryId();
  const {
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    updateDetails
  } = useEntityList();
  const { search } = useLocation();

  useEffect(() => {
    updateDetails({
      title: `
        We have identified the parties related to your subject based on data from Moody's which will include any UBOs, Shareholders and Directors
        found. In this section, you will be able to run reports and view the key findings from our research.`,
      label: "Ultimate Beneficial Owner threshold",
      value: "25%",
      helpText: <RelatedPartiesEntitySectionHelpText />,
      commissionedEntityListTitle: "Related Parties Reports",
      entityListTitle: "Related Parties identified"
    });

    const api = new EntitiesApi();

    api
      .getRelatedParties({ enquiryId })
      .then(({ response }) => {
        if (response) {
          updateCommissionedEntityList(response.commissionedEntityList);
          updateEntityList(response.entityList);
          updateFilters(response.entityFilters);
        }
      })
      .catch(() => {});
  }, [
    updateDetails,
    updateEntityList,
    updateCommissionedEntityList,
    updateFilters,
    search,
    enquiryId
  ]);

  return <EntitySection title="Related parties" />;
};

const RelatedPartiesResearch = () => {
  return (
    <EntityListContextProvider>
      <RelatedPartiesEntitySection />
    </EntityListContextProvider>
  );
};

export default RelatedPartiesResearch;
