import { type model_CommissionedRelatedParty } from "api/related-parties";
import { EntityStatus } from "api/entities";
import { transformEntity } from "./transformEntity";

export const transformCommissionedEntity = (
  entity: model_CommissionedRelatedParty
) => {
  const transformedEntity = transformEntity(entity);
  const getEntityStatus = (enquiryStatus: string) => {
    const statusMap: Record<string, EntityStatus> = {
      QUEUED: EntityStatus.queued,
      IN_PROGRESS: EntityStatus.inProgress,
      DONE: EntityStatus.done,
      FAILED: EntityStatus.failed
    };

    return statusMap[enquiryStatus];
  };

  const {
    enquiryExecutiveSummary: summary,
    enquiryId,
    enquiryFlags: riskFlags,
    enquiryState
  } = entity;

  const params = new URLSearchParams(window.location.search);
  const shareToken = params.get("token");

  const enquiryUrl = shareToken
    ? `/share/${enquiryId}?token=${shareToken}`
    : `/report/${enquiryId}`;

  return {
    ...transformedEntity,
    // TODO: backend should return null for blank strings. Remove this when it does.
    summary: summary || undefined,
    riskFlags,
    enquiryUrl,
    status: getEntityStatus(enquiryState)
  };
};
