import React, { useState, useRef, useEffect, type FC } from "react";

import { EntityStatus } from "api/entities";

import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

interface EntityCardSummaryProps {
  summary?: string;
  status?: EntityStatus;
}

const DEFAULT_SUMMARY =
  "There was insufficient data for Xapien to summarise your insights. This usually just means that the subject has a relatively low profile.";

const EntityCardSummary: FC<EntityCardSummaryProps> = ({
  summary = DEFAULT_SUMMARY,
  status
}) => {
  const summaryRef = useRef(null);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (summaryRef.current) {
      const { scrollHeight, offsetHeight } = summaryRef.current;
      setIsTruncated(scrollHeight > offsetHeight);
    }
  }, [summary]);

  if (status === EntityStatus.inProgress || status === EntityStatus.queued) {
    return (
      <>
        <Skeleton isLightMode variant="text" />
        <Skeleton isLightMode variant="text" width="60%" />
        <Skeleton isLightMode variant="text" />
        <Skeleton isLightMode variant="text" width="60%" />
      </>
    );
  }

  return (
    <>
      <S.Summary ref={summaryRef} showFullSummary={showFullSummary}>
        {summary}
      </S.Summary>

      <S.SummaryFooter>
        {isTruncated && (
          <S.ShowFullSummaryButton
            onClick={() => setShowFullSummary(prev => !prev)}
          >
            {showFullSummary ? "Show less" : "Show more"}
          </S.ShowFullSummaryButton>
        )}
      </S.SummaryFooter>
    </>
  );
};

export default EntityCardSummary;
