import React, { useEffect, useState } from "react";
import { NavItem } from "reactstrap";

import ReactTooltip from "components/atoms/ReactTooltip";

import { useNavigate } from "react-router-dom";
import { Layers } from "react-feather";
import { apm } from "@elastic/apm-rum";
import { ReportService } from "api/portal";
import S from "./styles";

interface Props {
  enquiryId: string;
}

const ViewERReportButton = ({ enquiryId }: Props) => {
  const navigate = useNavigate();

  const [subjectPersonaId, setSubjectPersonaId] = useState<string | null>(null);
  const [subjectPersonaIdIsReady, setSubjectPersonaIdIsReady] = useState(false);

  useEffect(() => {
    const fetchSubjectPersona = async () => {
      try {
        const id = await ReportService.getSubjectPersonaId({
          reportId: enquiryId
        });
        setSubjectPersonaId(id);
      } catch (e: unknown) {
        apm.captureError(e as Error);
        console.error("Error fetching subject persona ID:", e);
      } finally {
        setSubjectPersonaIdIsReady(true);
      }
    };

    fetchSubjectPersona();
  }, [enquiryId]);

  const handleNavigation = () => {
    if (!subjectPersonaIdIsReady) return; // Prevent early clicks

    // Default to the enquiry summary page
    // If there is a subject persona; navigate to that instead
    const erReportLocation = subjectPersonaId
      ? `/report/${enquiryId}/persona/${subjectPersonaId}`
      : `/report/summary/${enquiryId}`;

    navigate(erReportLocation);
  };

  return (
    <NavItem onClick={handleNavigation} active={subjectPersonaIdIsReady}>
      <S.ViewERButtonContainer>
        <ReactTooltip
          tooltip="View ER report"
          open={undefined}
          style={undefined}
          className={undefined}
        >
          <S.ViewERButton aria-label="View ER report">
            <Layers size={12} />
          </S.ViewERButton>
        </ReactTooltip>
      </S.ViewERButtonContainer>
    </NavItem>
  );
};

export default ViewERReportButton;
