import React from "react";
import LoginModal from "components/molecules/LoginModal";
import { AnimatePresence, motion } from "framer-motion";

const ThemedLogin = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <LoginModal isOpen />
      </motion.div>
    </AnimatePresence>
  );
};

export default ThemedLogin;
