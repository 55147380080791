import { styled } from "@linaria/react";
import { standardColors, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div<{ borderColor: string; borderWidth: string }>`
  padding: 16px;
  display: flex;
  color: ${grey.dark};
  border: ${({ borderWidth }) => borderWidth} solid
    ${({ borderColor }) => borderColor};
  border-radius: 8px;
  gap: 16px;
  font-size: ${fontSize.sm};
`;

const VerticalDivider = styled.div`
  border-right: 1px solid ${grey.rule};
`;

const Content = styled.div`
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FullContent = styled(Content)`
  flex: 1;
  max-width: none;
`;

const Title = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ color }) => color || standardColors.black};

  &.loading {
    animation: loading 1.5s ease-in-out 0.5s infinite;
  }

  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Subtitle = styled.div``;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 8px;
`;

const S = {
  Actions,
  Container,
  FullContent,
  Content,
  Title,
  Subtitle,
  VerticalDivider
};

export default S;
