import { getAgeFromDob } from "util/getAgeFromDob";
import { UncertainDate } from "api/reportPersonas/types";
import { nationalities } from "./nationalities";

/**
 * Validate if a date is valid (not null and not in the future).
 * @param {Date | null} date - The date to validate
 * @param {Date} currentDate - The reference date for comparison
 * @returns {boolean} True if the date is valid, false otherwise
 */
function validDate(date: Date | null, currentDate: Date = new Date()) {
  return date ? date <= currentDate : false;
}

function formatMonth(date: Date) {
  return date.toLocaleString("default", { month: "short" });
}

function nth(day: number) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function hasMonthPassed(currentDate: Date, latest: Date) {
  return (
    currentDate.getFullYear() === latest.getFullYear() &&
    (currentDate.getMonth() > latest.getMonth() ||
      (currentDate.getMonth() === latest.getMonth() &&
        currentDate.getDate() >= latest.getDate()))
  );
}

/**
 * Formats an uncertain date range into a human-readable string
 * @param {UncertainDate} uncertainDate - The uncertain date object
 * @param {Date} [currentDate=new Date()] - The reference date
 * @returns {string | null} The formatted date string, if possible
 */
export function formatUncertainDate(
  uncertainDate: UncertainDate,
  currentDate: Date = new Date()
): string | null {
  const { earliestPossible, latestPossible } = uncertainDate;

  let earliest = earliestPossible
    ? new Date(
        earliestPossible.year,
        earliestPossible.month - 1,
        earliestPossible.day
      )
    : null;

  let latest = latestPossible
    ? new Date(
        latestPossible.year,
        latestPossible.month - 1,
        latestPossible.day
      )
    : null;

  const isEarliestPossibleValid = validDate(earliest, currentDate);
  const isLatestPossibleValid = validDate(latest, currentDate);

  if (!isEarliestPossibleValid && !isLatestPossibleValid) {
    return null;
  }

  // If either date is invalid (e.g. in the future), make it null instead
  earliest = isEarliestPossibleValid ? earliest : null;
  latest = isLatestPossibleValid ? latest : null;

  // If earliest is after latest, date is invalid
  if (earliest && latest && earliest > latest) {
    // Date received was invalid
    return null;
  }

  const earliestYear = earliest?.getFullYear() ?? null;
  const latestYear = latest?.getFullYear() ?? null;
  const earliestAge = earliest ? getAgeFromDob(earliest, currentDate) : null;
  const latestAge = latest ? getAgeFromDob(latest, currentDate) : null;

  if (latestAge !== null && latestAge <= 10) {
    return null;
  }

  if (!earliest) {
    return `before ${latestYear} (>= ${latestAge})`;
  }

  if (!latest) {
    return `after ${earliestYear} (<= ${earliestAge})`;
  }

  if (earliestYear === latestYear) {
    // If both dates are the same
    const sameDate =
      earliest.getMonth() === latest.getMonth() &&
      earliest.getDate() === latest.getDate();

    if (sameDate) {
      const day = earliest.getDate();
      const month = formatMonth(earliest);
      return `${day}${nth(day)} ${month} ${earliestYear} (${earliestAge})`;
    }

    // Check if the latest month has passed compared to current month. To decide if we can output single age or age range.
    const hasMonthsPassed = hasMonthPassed(currentDate, latest);

    const ageRange =
      hasMonthsPassed || earliestAge === latestAge
        ? `(${earliestAge})`
        : `(${latestAge} or ${earliestAge})`;

    // If they span all months do not output months.
    const spansEntireYear =
      earliest.getMonth() === 0 && latest.getMonth() === 11;
    if (spansEntireYear) {
      return `${earliestYear} ${ageRange}`;
    }

    // If same month, only output once.
    if (earliest.getMonth() === latest.getMonth()) {
      return `${formatMonth(earliest)} ${earliestYear} ${ageRange}`;
    }

    // Spans multiple months, output both.
    return `${formatMonth(earliest)}-${formatMonth(
      latest
    )} ${earliestYear} ${ageRange}`;
  }

  // Spans multiple year, output just years.
  const ageRange =
    latestAge === earliestAge
      ? `(${earliestAge})`
      : `(${latestAge}-${earliestAge})`;

  return `${earliestYear}-${latestYear} ${ageRange}`;
}

export const getNationalityFromCountryCode = (countryCode: string) => {
  return nationalities[countryCode] ?? "Undefined";
};

export const openPersonaReport = (enquiryId: string, personaId: string) => {
  const personaReportURL = `/report/${enquiryId}/persona/${personaId}`;
  window.open(personaReportURL);
};

// Using regex here for UAT. Will need to change when productionising.
export function summarizeDescription(description: string) {
  // Check for direct role
  const rolePattern =
    /RoleGroupKey\s*{[^}]*Description\s*=\s*([^,}]*)[^}]*SpecificRoleType\s*=\s*([^,}]*)/;

  const match = description.match(rolePattern);
  if (match) {
    const descriptionPart = match[1].trim();
    const roleTypePart = match[2].trim();

    // Collect non-empty values
    const roles = [descriptionPart, roleTypePart].filter(
      value => value.length > 0
    );

    if (roles.length > 0) {
      return `Has role with description "${roles.join(", ")}"`;
    }
  }

  // Check for two-hop relationships
  const twoHopPattern = /-2HOP-/;
  if (twoHopPattern.test(description)) {
    return "Is closely associated with";
  }

  // Check for same doc relationships
  const sameDocPattern = /-SAME DOC-/;
  if (sameDocPattern.test(description)) {
    return "Mentioned alongside";
  }

  // Default case: return the description as is
  return description;
}
