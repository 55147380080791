import React, { MouseEventHandler, useContext } from "react";

import useRiskSummaries, {
  RiskSummariesStatus
} from "util/hooks/useRiskSummaries";
import { isPDX } from "static-config";
import { Minus, Info as InfoIcon } from "react-feather";
import { Link } from "react-router-dom";
import { RiskSummaryTopic } from "api/insights";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { getRiskSummaryIcon, getRiskSummaryTitle } from "util/riskSummary";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import config from "config";

import S from "./styles";

interface IconLeadingProps {
  variant?: "small" | "medium";
  isDisabled?: boolean;
}

const IconLeading = ({
  variant = "small",
  isDisabled = false
}: IconLeadingProps) => {
  return (
    <S.IconWrapper variant={variant} isDisabled={isDisabled}>
      <Minus />
    </S.IconWrapper>
  );
};

const getFormattedSummary = (input: string) =>
  input.replaceAll(/\*\*([^*]+)\*\*/g, "<span>$1</span>");

interface RiskSummaryTooltipProps {
  topic: RiskSummaryTopic;
  summary: string;
  hasDisclaimer: boolean;
  handleMute: (mute: boolean) => void;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const RiskSummaryTooltip = ({
  topic,
  summary,
  hasDisclaimer,
  handleMute,
  onMouseEnter,
  onMouseLeave
}: RiskSummaryTooltipProps) => {
  const { status, getSummary } = useRiskSummaries();
  const { enabled: diagnosticsModeEnabled } = useContext(
    DiagnosticsModeContext
  );

  const isMuted = !!getSummary(topic)?.muted;
  const hasErrored = status === RiskSummariesStatus.Error;
  const isMutingButtonDisabled = isPDX && !diagnosticsModeEnabled;
  const isLoading = status === RiskSummariesStatus.MutingRiskIcon;
  const isScreening =
    topic === RiskSummaryTopic.SANCTIONS ||
    topic === RiskSummaryTopic.WATCHLISTS ||
    topic === RiskSummaryTopic.PEPS;

  if (hasErrored) {
    return (
      <S.TooltipContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <S.TooltipBody>
          <S.TooltipIcon hasError>
            <InfoIcon />
          </S.TooltipIcon>
          <S.TooltipContent>
            <S.TooltipTitle hasError>We hit a snag</S.TooltipTitle>
            <S.TooltipText>
              We could not update the risk status. Please try again by reloading
              the page. If the issue persists, please contact{" "}
              <a href={`mailto:${config.supportEmail}`}>Customer Success</a>
            </S.TooltipText>
          </S.TooltipContent>
        </S.TooltipBody>
      </S.TooltipContainer>
    );
  }

  const title = getRiskSummaryTitle(topic);
  const Icon = getRiskSummaryIcon(topic);

  return (
    <S.TooltipContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {isLoading ? (
        <S.DiscardedCover>
          <S.Spinner />
        </S.DiscardedCover>
      ) : (
        isMuted && (
          <S.DiscardedCover>
            <IconLeading variant="medium" />
            <S.DiscardedCoverHeading level={4}>
              Marked as not risk
            </S.DiscardedCoverHeading>
            <S.ActionButton
              type={ButtonType.Filled}
              size={ButtonSize.Small}
              disabled={isMutingButtonDisabled}
              onClick={() => handleMute(false)}
              text="Undo"
            />
          </S.DiscardedCover>
        )
      )}
      <S.TooltipBody>
        <S.TooltipIcon>
          <Icon />
        </S.TooltipIcon>
        <S.TooltipContent>
          <S.TooltipTitle>{title}</S.TooltipTitle>
          <S.TooltipText
            dangerouslySetInnerHTML={{
              __html: getFormattedSummary(summary)
            }}
          />
          {hasDisclaimer && (
            <S.DisclaimerContainer>
              <S.DisclaimerStars />
              <S.DisclaimerText>AI-Generated Summary</S.DisclaimerText>
            </S.DisclaimerContainer>
          )}
          <S.ActionButton
            type={ButtonType.Filled}
            IconLeading={IconLeading}
            size={ButtonSize.Small}
            disabled={isMutingButtonDisabled}
            onClick={() => handleMute(true)}
            text="Mark as not risk"
          />
        </S.TooltipContent>
      </S.TooltipBody>
      {isScreening ? (
        <S.TooltipFooter>
          For more information, review the{" "}
          <Link to="#screening">Screening Section</Link>
        </S.TooltipFooter>
      ) : (
        <S.TooltipFooter>
          For more information, review the Insights section.
        </S.TooltipFooter>
      )}
    </S.TooltipContainer>
  );
};
